import Keycloak from "keycloak-js";

let keycloak = null;

if (typeof window !== "undefined") {
    const keycloak_login = require("../../keycloak.json");
    keycloak = new Keycloak(keycloak_login);
}

export default keycloak;
