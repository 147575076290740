import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "../../styles/main.scss";
import "../../styles/pages/_convert-ppt.scss";

import video from "../../images/products/convert-ppt/movie.png";
import powerpoint from "../../images/products/convert-ppt/ppt.png";
import arrow from "../../images/products/convert-ppt/arrow-right-short.svg";
import long_arrow from "../../images/products/convert-ppt/arrow-right.svg";

import Metadata from "../../js/Metadata";
import Navbar from "../../js/Navbar";
import Footer from "../../js/Footer";
import keycloak from "../../js/keycloak";

import { AiFillSound } from "@react-icons/all-files/ai/AiFillSound";
import AiAcademyToast from "../../js/layout/ai_academy_toast";

const defaultVoiceList = [
    {
        name: "detect",
        provider: "any",
        description: "Automatic voice selection",
    },
];

const ConvertpptPage = () => {
    const description =
        "Our widget enables you to make a commented video from your PowerPoint presentation";
    const convertBaseUrl = "https://ppt-to-video.themama.ai/";
    //const convertBaseUrl = "https://ppt-to-video-dev.ch.themama.ai/"; // prod doesn't work yet, use this instead
    const videoFormatUrl = convertBaseUrl + "convert";
    const loadVoicesUrl = convertBaseUrl + "voices";
    const voiceSampleUrl = convertBaseUrl + "voice_sample";

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isOpened, setIsOpened] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [validationErrors, setValidationErrors] = useState([]);
    const [playingAudio, setPlayingAudio] = useState(null);
    const [voiceList, setVoiceList] = useState(defaultVoiceList);
    const {
        register,
        handleSubmit,
        resetField,
        watch,
        formState: { errors },
    } = useForm();
    const [isUnauthenticatedError, setIsUnauthenticatedError] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        if (keycloak) {
            keycloak
                .init({ onLoad: "login-required", checkLoginIframe: false })
                .then((auth) => {
                    setIsAuthenticated(auth);
                    loadVoiceList();
                })
                .catch((err) => {
                    setIsAuthenticated(false);
                    setIsUnauthenticatedError(true);
                    console.error("cannot authenticate: ", err);
                });
        }
    }, []);

    const loadVoiceList = async () => {
        fetch(loadVoicesUrl, {
            method: "GET",
            headers: { Authorization: "Bearer " + keycloak.token },
        })
            .then(async (result) => {
                if (result.ok) {
                    const body = await result.json();
                    if (Array.isArray(body.voices)) {
                        const newVoiceArray = [];
                        for (const voice of body.voices) {
                            if (
                                voice.name &&
                                voice.provider &&
                                voice.description
                            ) {
                                newVoiceArray.push(voice);
                            }
                        }

                        if (newVoiceArray.length > 0) {
                            setVoiceList(newVoiceArray);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const loadVoiceSample = async (voice, speed) => {
        const result = await fetch(
            `${voiceSampleUrl}?voice=${voice}&speed=${speed}`,
            {
                method: "GET",
                headers: { Authorization: "Bearer " + keycloak.token },
            }
        ).catch((error) => {
            console.error(error);
        });

        if (result.ok) {
            const audioBlob = await result.blob();
            if (audioBlob != null) {
                const url = window.URL.createObjectURL(audioBlob);
                const newAudio = new Audio(url);
                newAudio.play();
                setPlayingAudio(newAudio);
            }
        }
    };

    async function fetchUrl(url, data) {
        let filename = "commented_video.mp4";

        if (!isLoading) {
            setIsLoading(true);
            setIsSuccess(false);
            setIsError(false);
            setErrorMessage("");
            setValidationErrors([]);
            fetch(url, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors",
                headers: { Authorization: "Bearer " + keycloak.token },
                body: data, // body data type must match "Content-Type" header
            })
                .then(async (result) => {
                    if (!result.ok) {
                        if (result.status === 422) {
                            const error = await result.json();
                            if (error) {
                                setErrorMessage(
                                    "There were some validation errors in your input:"
                                );

                                if (typeof error.detail === "string") {
                                    setValidationErrors([error.detail]);
                                } else {
                                    const errors = [];
                                    error.detail.forEach((element) => {
                                        errors.push(
                                            element.loc[1].replace("_", " ") +
                                                ": " +
                                                element.msg
                                        );
                                    });

                                    setValidationErrors(errors);
                                }
                            } else {
                                setErrorMessage(
                                    "There was an error while converting your PowerPoint, please try again."
                                );
                            }
                        } else if (result.status === 401) {
                            setErrorMessage(
                                "Your session has expired, you will be redirected to login shortly."
                            );
                            setInterval(() => {
                                login();
                            }, 5000);
                        } else {
                            setErrorMessage(
                                "There was an error while converting your PowerPoint, please try again."
                            );
                        }
                        setIsLoading(false);
                        setIsError(true);
                        return;
                    }
                    return result.blob();
                })
                // We use the download property for triggering the download of the file from our browser.
                // More information about the following code can be found here: https://stackoverflow.com/questions/32545632/how-can-i-download-a-file-using-window-fetch.
                // The filename from the first promise is used as name of the file.
                .then((blob) => {
                    if (blob != null) {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");
                        a.href = url;
                        a.download = filename;
                        a.click();
                        setIsLoading(false);
                        setIsSuccess(true);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    setIsError(true);
                    if (errorMessage === "") {
                        setErrorMessage(
                            "There was an error while converting your PowerPoint, please try again."
                        );
                    }
                    console.error(error);
                })
                .finally(() => {
                    resetField("assignment_file");
                });
        }
    }

    const login = () => {
        if (keycloak) {
            keycloak.login();
        }
    };

    const handleVideoSubmit = (data) => {
        const formData = new FormData();

        formData.append("assignment_file", data.assignment_file[0]);
        formData.append("voice", data.voice);
        formData.append("speed", data.speed);
        formData.append("silence_between_slides", data.silence_between_slides);
        formData.append("background_audio", data.background_audio);

        if (keycloak) {
            keycloak
                .updateToken(5)
                .then(fetchUrl(videoFormatUrl, formData))
                .catch((error) => {
                    console.log(error);
                    setIsAuthenticated(false);
                    setIsUnauthenticatedError(true);
                });
        }
    };

    const handleOpenBtn = (e) => {
        e.preventDefault();
        setIsOpened(!isOpened);
    };

    const checkHttpUrl = (url) => {
        if (url === "") return true;

        try {
            new URL(url);
        } catch (error) {
            return false;
        }
        return /^(https):\/\/(www.)?(.*?)\.(wav)(\?.*)?$/.test(url);
    };

    const voice = watch("voice");

    return (
        <div className="container-max-width">
            <Metadata
                title="PPT to Video Converter"
                url="https://themama.ai/products/convert-ppt"
                description={description}
            />
            <Navbar />
            <section className="section pb-3 pt-5">
                <div className="container pt-4 pb-3">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-lg-5 text-center">
                            <h1 className="pt-3">PPT to Video Converter</h1>
                        </div>
                        <div className="col-2 col-lg-1 pt-3">
                            <img
                                className="logo_images img-fluid w-100"
                                src={powerpoint}
                                alt="pptImage"
                            />
                        </div>
                        <div className="col-2 col-lg-1 pt-3">
                            <img
                                className="logo_arrow w-100"
                                src={arrow}
                                alt="arrow"
                            />
                        </div>
                        <div className="col-2 col-lg-1 pt-3">
                            <img
                                className="logo_images img-fluid w-100"
                                src={video}
                                alt="videoImage"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section pb-5 pt-5 section--light">
                <div className="container pb-4">
                    <div className="row form-row align-items-center">
                        <div className="col-12">
                            {!isUnauthenticatedError && (
                                <form
                                    className={`ppt2video-form needs-validation ${
                                        isLoading ? "d-none" : ""
                                    }`}
                                    id="ppt2video-form"
                                    onSubmit={handleSubmit(handleVideoSubmit)}
                                >
                                    <div className="input-group mb-3 justify-content-center has-validation m-auto ppt2video-form__group">
                                        <div className="col-xxl-6 col-xl-7 col-lg-9 col-10">
                                            <input
                                                type="file"
                                                className={`form-control ${
                                                    errors.assignment_file
                                                        ? "is-invalid"
                                                        : ""
                                                }`}
                                                id="assignment_file"
                                                accept=".ppt, .pptx"
                                                aria-invalid={
                                                    errors.assignment_file
                                                        ? "true"
                                                        : "false"
                                                }
                                                {...register(
                                                    "assignment_file",
                                                    {
                                                        required:
                                                            "This field is required",
                                                    }
                                                )}
                                            />
                                            {errors.assignment_file && (
                                                <div className="invalid-feedback">
                                                    {
                                                        errors.assignment_file
                                                            ?.message
                                                    }
                                                </div>
                                            )}
                                        </div>
                                        <div className="w-100" />
                                        <div className="col-xxl-5 col-xl-6 col-md-8 col-10">
                                            <div className="pt-4">
                                                <a
                                                    className="collapse-arrow collapsed text-decoration-none text-center d-block"
                                                    data-bs-toggle="collapse"
                                                    href="#more-options-btn"
                                                    role="button"
                                                    aria-expanded="false"
                                                    aria-controls="more-options-btn"
                                                    onClick={handleOpenBtn}
                                                >
                                                    {isOpened
                                                        ? "Fewer"
                                                        : "More"}{" "}
                                                    options
                                                </a>
                                                <div
                                                    className="collapse"
                                                    id="more-options-btn"
                                                >
                                                    <div className="row align-items-center pt-4">
                                                        <div className="col-lg-3">
                                                            <label
                                                                htmlFor="voice"
                                                                className="col-form-label"
                                                            >
                                                                Voice
                                                            </label>
                                                        </div>
                                                        <div className="col input-group">
                                                            <select
                                                                className="form-control custom-select form-select input-dropdown"
                                                                aria-label="Select voice"
                                                                id="voice"
                                                                defaultValue="any/detect"
                                                                {...register(
                                                                    "voice"
                                                                )}
                                                            >
                                                                {voiceList.map(
                                                                    (
                                                                        item,
                                                                        _index
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                _index
                                                                            }
                                                                            value={
                                                                                item.provider +
                                                                                "/" +
                                                                                item.name
                                                                            }
                                                                        >
                                                                            {
                                                                                item.description
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    if (
                                                                        playingAudio
                                                                    ) {
                                                                        playingAudio.pause();
                                                                    }
                                                                    loadVoiceSample(
                                                                        voice,
                                                                        speed.value
                                                                    );
                                                                }}
                                                                className="btn btn-outline-secondary d-flex align-items-center"
                                                                disabled={
                                                                    voice ===
                                                                    "any/detect"
                                                                }
                                                            >
                                                                <AiFillSound />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-4 align-items-center">
                                                        <div className="col-lg-3">
                                                            <label
                                                                htmlFor="speed"
                                                                className="col-form-label"
                                                            >
                                                                Speed
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-9 col-12 speed-range pt-lg-2">
                                                            <input
                                                                type="range"
                                                                className="form-range speed-range__input"
                                                                min="80"
                                                                max="120"
                                                                step="5"
                                                                id="speed"
                                                                defaultValue="100"
                                                                {...register(
                                                                    "speed",
                                                                    {
                                                                        valueAsNumber: true,
                                                                    }
                                                                )}
                                                            ></input>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <p className="speed-range__value">
                                                                        Slow
                                                                    </p>
                                                                </div>
                                                                <div className="col text-center">
                                                                    <p className="speed-range__value">
                                                                        Normal
                                                                    </p>
                                                                </div>
                                                                <div className="col text-end">
                                                                    <p className="speed-range__value">
                                                                        Fast
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-4">
                                                        <div className="col-lg-3">
                                                            <label
                                                                htmlFor="silence_between_slides"
                                                                className="col-form-label"
                                                            >
                                                                Pause between
                                                                slides
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-9 col-12 speed-range pt-lg-2">
                                                            <input
                                                                type="range"
                                                                className="form-range speed-range__input"
                                                                min="0"
                                                                max="10000"
                                                                step="1000"
                                                                id="silence_between_slides"
                                                                defaultValue="0"
                                                                {...register(
                                                                    "silence_between_slides",
                                                                    {
                                                                        valueAsNumber: true,
                                                                    }
                                                                )}
                                                            ></input>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <p className="speed-range__value">
                                                                        0s
                                                                    </p>
                                                                </div>
                                                                <div className="col text-center">
                                                                    <p className="speed-range__value">
                                                                        5s
                                                                    </p>
                                                                </div>
                                                                <div className="col text-end">
                                                                    <p className="speed-range__value">
                                                                        10s
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-4">
                                                        <div className="col-lg-3">
                                                            <label
                                                                htmlFor="background_audio"
                                                                className="col-form-label pt-0"
                                                            >
                                                                Background audio
                                                            </label>
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                type="text"
                                                                className={`form-control ${
                                                                    errors.background_audio
                                                                        ? "is-invalid"
                                                                        : ""
                                                                }`}
                                                                id="background_audio"
                                                                placeholder="URL to an audio file (wav format)"
                                                                aria-invalid={
                                                                    errors.background_audio
                                                                        ? "true"
                                                                        : "false"
                                                                }
                                                                {...register(
                                                                    "background_audio",
                                                                    {
                                                                        validate:
                                                                            (
                                                                                value
                                                                            ) =>
                                                                                checkHttpUrl(
                                                                                    value
                                                                                ),
                                                                    }
                                                                )}
                                                            />
                                                            {errors.background_audio && (
                                                                <div className="invalid-feedback">
                                                                    This is not
                                                                    a valid wav
                                                                    URL
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button
                                            disabled={!isAuthenticated}
                                            type="submit"
                                            id="submit-ppt"
                                            className="btn btn-primary text-white english-btn mt-3"
                                        >
                                            Turn into video
                                        </button>
                                    </div>
                                </form>
                            )}
                            {isUnauthenticatedError && (
                                <div className="text-center">
                                    <p className="pt-3">
                                        There was an error while logging in.
                                        Please try again.
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-primary text-white english-btn mt-3"
                                        onClick={login}
                                    >
                                        Log in
                                    </button>
                                </div>
                            )}
                            {isLoading && (
                                <div className="text-center">
                                    <div
                                        className="spinner-border blue-spinner"
                                        role="status"
                                    >
                                        <span className="visually-hidden">
                                            Loading...
                                        </span>
                                    </div>
                                    <p className="pt-3">
                                        The video is being created and will be
                                        downloaded shortly.
                                    </p>
                                </div>
                            )}
                            {isError && (
                                <div className="text-center">
                                    <p className="pt-3 mb-1">{errorMessage}</p>
                                    {validationErrors.map((item, index) => (
                                        <p className="mb-1" key={index}>
                                            {item}
                                        </p>
                                    ))}
                                </div>
                            )}
                            {isSuccess && (
                                <p className="text-center pt-3">
                                    Your PowerPoint was converted successfully.
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <section className="section pb-5 pt-5 ps-lg-5">
                <div className="container">
                    <div className="row align-items-center text-center">
                        <div className="col-5 col-lg-auto ps-lg-0">
                            <h2>PPT with speaker notes </h2>
                        </div>
                        <div className="col-2 col-lg-auto">
                            <img
                                className="logo_long_arrow"
                                src={long_arrow}
                                alt="arrow"
                            />
                        </div>
                        <div className="col-5 col-lg-auto">
                            <h2>commented video talk</h2>
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-lg-10 col-xxl-6 ps-lg-0">
                            <p className="mb-0">
                                {" "}
                                Add speaker notes to slides in your PowerPoint
                                presentation, upload the PPT file to our tool
                                and get your commented video talk back in a
                                moment.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <AiAcademyToast />
            <Footer />
        </div>
    );
};

export default ConvertpptPage;
